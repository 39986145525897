<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'

export default {
  name: 'distriBution',
  data() {
    return {
      ...api.command.getState(),
      id: ''
    }
  },
  mounted() {
    const { id } = this.$route.query
    this.id = id
    api.command.getList.call(this, {
      url: '/firmUser/page',
      paramsValue: { firmId: id },
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '用户电话',
          type: 'input',
          key: 'phone'
        },
        {
          label: 'text'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'userId',
          title: 'ID',
          type: 'lt-100',
          isId: false,
          sorter: (a, b) => a.userId - b.userId
        },
        {
          dataIndex: 'phone',
          title: '用户电话',
          type: 'lt-100',
          sorter: (a, b) => a.phone - b.phone
        },
        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗？',
                onClick: () => {
                  api.command.del
                    .call(this, {
                      url: '/firmUser/delete',
                      params: {
                        firmId: this.id,
                        userId: records.userId
                      }
                    })
                    .then()
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push(`/infoMain/distriButionUser?id=${this.id}`)
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
